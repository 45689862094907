import React from 'react';
import '../index.css'; // Import the CSS file for styling

const songs = [
  { title: "Here for the Party", artist: "Gretchen Wilson" },
  { title: "Hands to Yourself", artist: "Georgia Satellites" },
  { title: "Summer of 69", artist: "Bryan Adams" },
  { title: "Feel Like Dancing", artist: "Headpins" },
  { title: "Blinding Lights", artist: "Weeknd Rock Cover" },
  { title: "Vida Loca", artist: "Ricky Martin" },
  { title: "Honey I'm Home", artist: "Shania Twain" },
  { title: "Valerie", artist: "Amy Winehouse" },
  { title: "Hate Myself for Loving You", artist: "Joan Jett" },
  { title: "Funky Music", artist: "Wild Cherry" },
  { title: "Flowers", artist: "Miley Cyrus" },
  { title: "Levitating", artist: "Dua Lipa" },
  { title: "Havana Smooth", artist: "Mash up" },
  { title: "Bitch", artist: "Merideth Brooks" },
  { title: "Rich", artist: "Maren Morris" },
  { title: "Rolling in the Deep", artist: "Adele" },
  { title: "That Song", artist: "Big Wreck" },
  { title: "Take Me Down", artist: "Pretty Reckless" },
  { title: "Hard to Handle", artist: "Black Crows" },
  { title: "Run to You", artist: "Bryan Adams" },
  { title: "Red Neck Woman", artist: "Gretchen Wilson" },
  { title: "Venus", artist: "Shocking Blue" },
  { title: "The Middle", artist: "Jimmy Eat World" },
  { title: "Call Me", artist: "Sing it Live" },
  { title: "Hard Days Night", artist: "The Beatles" },
  { title: "Make Love to You", artist: "Halestorm" },
  { title: "Black Velvet", artist: "Alannah Myles" },
  { title: "What's Up", artist: "4 Non Blondes" },
  { title: "Kiss Me Deadly", artist: "Lita Ford" },
  { title: "Superstition", artist: "Phil X" },
  { title: "Feel Like a Woman", artist: "Shania Twain" },
  { title: "Someone Who's Cool", artist: "ODDS" },
  { title: "Fooled Around and Fell in Love", artist: "Winery Dogs" },
  { title: "Tennessee Whiskey", artist: "Chris Stapleton" },
  { title: "Volcano Girls", artist: "Veruca Salt" },
  { title: "Secrets That You Keep", artist: "Romantics" },
  { title: "La Bamba/Twist", artist: "Mash up" },
  { title: "Square Hammer", artist: "Ghost" },
  { title: "Kick It Out", artist: "Heart" },
];

const SongList = () => {
  return (
    <div className="song-list">
      {songs.map((song, index) => (
        <div key={index} className="song-item">
          <div className="song-title">{song.title}</div>
          <div className="artist-name">{song.artist}</div>
        </div>
      ))}
    </div>
  );
};

export default SongList;
