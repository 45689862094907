import React, { useState } from 'react';
import EmailForm from './contact';

const ContactButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <center><button onClick={handleOpen} class="btn11-hover color-1"><span>Book The Band!</span></button></center>
      <EmailForm open={open} handleClose={handleClose} />
    </div>
  );
};

export default ContactButton;
