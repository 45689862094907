import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Snackbar, Alert } from '@mui/material';

const EmailForm = ({ open, handleClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [paRequired, setPaRequired] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    if (!name || !email || !date || !paRequired) {
      setSnackbarMessage('Please fill out all required fields.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return; // Prevent form submission
    }

    const serviceId = 'service_5qhmtjw';
    const templateId = 'template_kd972tc';
    const userId = 'uwj7c63klbwb10Mux';

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
      pa_required: paRequired,
      location: location,
      date: date,
    };

    emailjs.send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setSnackbarMessage('Email sent successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        // Clear form fields
        setName('');
        setEmail('');
        setMessage('');
        setPaRequired('');
        setLocation('');
        setDate('');
        handleClose(); // Close the modal on successful submission
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSnackbarMessage('Error sending email. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {open && (
        <div className="modal-412">
          <div className="modal-content-412">
            <span className="close-412" onClick={handleClose}>&times;</span>
            <h2 className="header-412">Book The Band</h2>
            <p className="subtext-412">Give us some info about your event, and we'll get back to you soon. Thanks!</p>
            <p className="subtext-412">* = Required</p>
            <form onSubmit={handleSubmit}>
              <div className="form-row-412">
                <div className="form-group-412">
                  <label className="label-412">Your Name*</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="input-412"
                  />
                </div>
                <div className="form-group-412">
                  <label className="label-412">Contact Email*</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="input-412"
                  />
                </div>
              </div>
              <div className="form-group-412">
                <label className="label-412">Event Date*</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                  className="input-412"
                />
              </div>
              <div className="form-row-412">
                <div className="form-group-412">
                  <label className="label-412">Location of Event</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="input-412"
                  />
                </div>
                <div className="form-group-412">
                  <label className="label-412">PA Required*</label>
                  <select
                    value={paRequired}
                    onChange={(e) => setPaRequired(e.target.value)}
                    required
                    className="select-412"
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              <div className="form-group-412">
                <label className="label-412">Extra Info</label>
                <textarea
                  rows="4"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="textarea-412"
                ></textarea>
              </div>
              </div>

              <center><button type="submit" className="btn11-hover color-1">Send Email</button></center>
            </form>
          </div>
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EmailForm;
