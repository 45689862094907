import React from 'react';
import { Button } from '@mui/material';

const PostcardPreview = () => {
  const containerStyle = {
    borderRadius: '8px',
    padding: '8px', // Reduced padding for a smaller container
    display: 'inline-block',
  };

  const textStyle = {
    color: 'black',
    margin: 0,
    fontSize: '12px', // Reduced font size
  };

  return (
    <div style={containerStyle}>
      <p style={textStyle}>
        <Button
          variant="outlined"
          size="small"
          component="a"
          href="https://www.facebook.com/TheCardMan"
          target="_blank"
          sx={{
            margin: '0 4px', // Reduced margin
            fontSize: '10px', // Smaller font size
            padding: '2px 6px', // Smaller padding
            color: 'black',
            borderColor: 'black',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none', // Remove underline
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
              borderColor: 'black',
            },
          }}
        >
          📷 James McColl
        </Button>
        <Button
          variant="outlined"
          size="small"
          component="a"
          href="https://www.facebook.com/Paula.Baker.Leroux"
          target="_blank"
          sx={{
            margin: '0 4px', // Reduced margin
            fontSize: '10px', // Smaller font size
            padding: '2px 6px', // Smaller padding
            color: 'black',
            borderColor: 'black',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none', // Remove underline
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
              borderColor: 'black',
            },
          }}
        >
          📷 Paula Leroux
        </Button>
        <Button
          variant="outlined"
          size="small"
          component="a"
          href="https://ca.linkedin.com/in/bob-maccallum-208bb836"
          target="_blank"
          sx={{
            margin: '0 4px', // Reduced margin
            fontSize: '10px', // Smaller font size
            padding: '2px 6px', // Smaller padding
            color: 'black',
            borderColor: 'black',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none', // Remove underline
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
              borderColor: 'black',
            },
          }}
        >
          📷  Bob Maccallum
        </Button>
      </p>
    </div>
  );
};

export default PostcardPreview;
