import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SongSuggestionForm from './songrequest';

const ContactButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <center>
        <IconButton
          className="music-icon-button2"
          onClick={handleOpen}
        >
          <MusicNoteIcon className="music-icon2" />
          <span className="musicbutton-text2">Suggest a Song</span>
        </IconButton>
      </center>
      <SongSuggestionForm open={open} handleClose={handleClose} />
    </div>
  );
};

export default ContactButton;
