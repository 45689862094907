import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import YouTube from 'react-youtube';

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Mmodalyt extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <div class="watchhere" onClick={this.onOpenModal}><b>Watch It Here</b></div>
        <Modal open={open} onClose={this.onCloseModal}>
          <center><h2 className=""><b>Blu Martini Set</b></h2></center>
          <hr className="yellow" />
          <div id="simple-modal-description" style={{ borderRadius: '12px', overflow: 'hidden' }}>
            <YouTube videoId="v3MSayUHyA4" /> {/* Replace YOUR_VIDEO_ID with your actual YouTube video ID */}
          </div>
          <br />
          <hr className="yellow" />
          <br />
        </Modal>
      </div>
    );
  }
}

export default Mmodalyt;
