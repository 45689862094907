import React from 'react';
import "../Bios/biostyle.css"; // Include custom CSS for animations
import Davebio from '../../dark/Bios/Dave'
import Gbio from '../../dark/Bios/G'
import Gregbio from '../../dark/Bios/Greg'
import Kiribio from '../../dark/Bios/Kiri'
import Reconbio from '../../dark/Bios/Recon'
const Team = () => {
  return (
    <div className="container12322">
      <div className="row">
        {/* Team Member 1 */}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="our-team">
            <div className="picture">
              <img className="img-fluid" src="https://i.imgur.com/zHU3sVl.png" alt="Kiri" />
            </div>
            <div className="team-content">
              <h3 className="name">Kiri</h3>
              <h4 className="title">Lead Vocals</h4>
              <Kiribio/>
              <br/>
            </div>
            <ul className="social">
              <li></li>
            </ul>
          </div>
        </div>

        {/* Team Member 2 */}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="our-team">
            <div className="picture">
              <img className="img-fluid" src="https://i.imgur.com/lwICfDE.png" alt="G" />
            </div>
            <div className="team-content">
              <h3 className="name">G</h3>
              <h4 className="title">Bass Guitar</h4>
              <Gbio/>
              <br/>
            </div>
            <ul className="social">
              <li></li>
            </ul>
          </div>
        </div>

        {/* Team Member 3 */}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="our-team">
            <div className="picture">
              <img className="img-fluid" src="https://i.imgur.com/MXYJg2M.png" alt="Greg" />
            </div>
            <div className="team-content">
              <h3 className="name">Greg</h3>
              <h4 className="title">Drums</h4>
              <Gregbio/>
              <br/>
            </div>
            <ul className="social">
              <li></li>
            </ul>
          </div>
        </div>

        {/* Team Member 4 */}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="our-team">
            <div className="picture">
              <img className="img-fluid" src="https://i.imgur.com/jDESwGR.png" alt="Dave" />
            </div>
            <div className="team-content">
              <h3 className="name">Dave</h3>
              <h4 className="title">Guitar</h4>
              <Davebio/>
              <br/>
            </div>
            <ul className="social">
              <li></li>
            </ul>
          </div>
        </div>
        {/* Team Member 5*/}
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="our-team">
            <div className="picture">
              <img className="img-fluid" src="https://i.imgur.com/W687FqV.png" alt="Recon/Dave" />
            </div>
            <div className="team-content">
              <h3 className="name">Dave/Recon</h3>
              <h4 className="title">Guitar</h4>
              <Reconbio/>
              <br/>
            </div>
            <ul className="social">
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
