import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Snackbar, Alert } from '@mui/material';

const SongSuggestionForm = ({ open, handleClose }) => {
  const [artistName, setArtistName] = useState('');
  const [songTitle, setSongTitle] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    if (!artistName || !songTitle) {
      setSnackbarMessage('Please fill out all required fields.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return; // Prevent form submission
    }

    const serviceId = 'service_5qhmtjw';
    const templateId = 'template_d8pjy7p';
    const userId = 'uwj7c63klbwb10Mux';

    const templateParams = {
      artist_name: artistName,
      song_title: songTitle,
      youtube_link: youtubeLink,
    };

    emailjs.send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log('Suggestion sent successfully:', response);
        setSnackbarMessage('Suggestion sent successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        // Clear form fields
        setArtistName('');
        setSongTitle('');
        setYoutubeLink('');
        handleClose(); // Close the modal on successful submission
      })
      .catch((error) => {
        console.error('Error sending suggestion:', error);
        setSnackbarMessage('Error sending suggestion. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {open && (
        <div className="modal-412">
          <div className="modal-content-412">
            <span className="close-412" onClick={handleClose}>&times;</span>
            <h2 className="header-412">Song Suggestion</h2>
            <p className="subtext-412">Do you have a song in mind that you think would perfectly match our vibe? <br/>Suggest it below, and it might just make it onto our setlist!</p>
            <p className="subtext-412">* = Required</p>
            <form onSubmit={handleSubmit}>
              <div className="form-row-412">
                <div className="form-group-412">
                  <label className="label-412">Artist Name*</label>
                  <input
                    type="text"
                    value={artistName}
                    onChange={(e) => setArtistName(e.target.value)}
                    required
                    className="input-412"
                  />
                </div>
                <div className="form-group-412">
                  <label className="label-412">Song Title*</label>
                  <input
                    type="text"
                    value={songTitle}
                    onChange={(e) => setSongTitle(e.target.value)}
                    required
                    className="input-412"
                  />
                </div>
              </div>
              <div className="form-group-412">
                <label className="label-412">YouTube Link</label>
                <input
                  type="text"
                  value={youtubeLink}
                  onChange={(e) => setYoutubeLink(e.target.value)}
                  className="input-412"
                />
              </div>
              <center><button type="submit" className="btn11-hover color-1">Send Suggestion</button></center>
            </form>
          </div>
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SongSuggestionForm;
