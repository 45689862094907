import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Bios/biostyle.css"; // Include custom CSS for animations

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Kiribio extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="newbtnsonglist3" onClick={this.onOpenModal}><b>Biography</b></button>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          animationDuration={500} // Animation duration for modal open
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
        >
          <div className="modal-header">
            <img
              src="https://i.imgur.com/6w0sFwQ.png" 
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto"
              }}
            />
          </div>
          <hr className="yellow" />
          <div className="modal-body">
            <img
              src="https://i.imgur.com/zHU3sVl.png" 
              alt="Kiri"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                border: "5px solid #E1232A",
                float: "left",
                marginRight: "15px"
              }}
            />
            <p style={{ color: '#000', fontSize: '16px', textAlign: 'left', textIndent: '20px' }}>
              Kiri has enjoyed music from a young age and loves to perform live. Originating from Cuba, Kiri developed her skills as a vocal coach and resort performer where she entertained guests from all over the world, singing classics from Whitney Houston, Adele, and Celine Dion. Kiri now calls Kingston, Ontario, home and is looking forward to entertaining the people of Eastern Ontario.
            </p>
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Kiribio;
