// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";
import DarkPortfolioLanding from "./dark/PortfolioLanding";
import Aboutus from "./dark/SecondPage"; 
import Calendar from "./dark/Calendar.jsx"; 
import Support from "./dark/Support.jsx"; 
import Blog from "./dark/Blogs/main.jsx"; 
import Media from "./dark/MediaPage.jsx"; 
import * as serviceWorker from "./serviceWorker";

const Root = () => (
  <Router>
    <div>
      <Route exact path="/" component={DarkPortfolioLanding} />
      <Route path="/twitch" component={Aboutus} />
      <Route path="/Calendar" component={Calendar} />
      <Route path="/Media" component={Media} />
      <Route path="/Support" component={Support} />
      <Route path="/Blog" component={Blog} />
    </div>
  </Router>
);

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
