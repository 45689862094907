import React, { useState, useEffect } from 'react';

const RandomImageComponent = ({ imageArray, className }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      // Cycle to the next image
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => {
      // Cleanup the timer on component unmount
      clearInterval(timer);
    };
  }, [imageArray]);

  return (
    <div
      style={{
        backgroundImage: `url('${imageArray[currentImageIndex]}')`,
        transition: 'background-image 1s ease-in-out', // Add a fade transition
      }}
      className={`postcard__img ${className}`}
    ></div>
  );
};

export default RandomImageComponent;
