import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FaCamera, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing Font Awesome icons

const importAll = (r) => r.keys().map(r);

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const youtubeLinks = [
  { url: "https://www.youtube.com/embed/X906YakZlKI", title: "Twisty La Bamba @Music Series" },
  { url: "https://www.youtube.com/embed/DxJ5cXaGzTw", title: "Superstition @Blu Martini" },
  { url: "https://www.youtube.com/embed/CwYqGJi06Qo", title: "Dancing @Music Series" },
  { url: "https://www.youtube.com/embed/HUAcODXNIYE", title: "Havana @Music Series" },
  { url: "https://www.youtube.com/embed/6G9TqqLDUhs", title: "Don't It Make ya @DT Kingston" },
  { url: "https://www.youtube.com/embed/xPjOe20Pnik", title: "Funky @Yacht Club" }
];

const overlayImagePath = require('../assets/images/showimages/preview/overlay_image.png').default; // Ensure .default for webpack

const ImageGallery = () => {
  const [media, setMedia] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [clickedVideos, setClickedVideos] = useState({});
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const importedMedia = importAll(require.context('../assets/images/showimages', false, /\.(png|jpe?g|svg)$/));
    setMedia(shuffleArray([...importedMedia, ...youtubeLinks]));
  }, []);

  const getMediaName = (media) => {
    if (typeof media === 'object' && media.title) {
      return media.title;
    }
    const parts = media.default.split('/');
    const filename = parts[parts.length - 1];
    return filename.split('.')[0];
  };

  const isYouTubeVideo = (media) => typeof media === 'object' && media.url && media.url.includes('youtube.com');

  const handleVideoClick = (index) => {
    setClickedVideos({ ...clickedVideos, [index]: true });
  };

  const visibleMedia = showMore ? media : media.slice(0, 4); // Adjust the slice number for how many items you want to initially display

  return (
    <div className="image-gallery">
      {visibleMedia.map((item, index) => (
        <div key={index} className="media-container">
          {isYouTubeVideo(item) ? (
            clickedVideos[index] ? (
              <iframe
                className="gallery-media"
                width="560"
                height="315"
                src={item.url}
                title={item.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <img
                src={overlayImagePath}
                alt="Video Overlay"
                className="gallery-media overlay-image"
                onClick={() => handleVideoClick(index)}
              />
            )
          ) : (
            <img
              src={item.default}
              alt={getMediaName(item)}
              className="gallery-media"
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
            />
          )}
          <div className="caption">{getMediaName(item)}</div>
        </div>
      ))}
      {media.length > 4 && ( // Adjust this number based on your slice
        <button onClick={() => setShowMore(!showMore)} className="btn11-hover color-1 toggle-button">
          {showMore ? (
            <>
              <FaChevronUp /> Show Less Images
            </>
          ) : (
            <>
              <FaChevronDown /> Show More Images
            </>
          )}
        </button>
      )}
      {isOpen && !isYouTubeVideo(media[photoIndex]) && (
        <Lightbox
          mainSrc={media[photoIndex].default}
          nextSrc={media[(photoIndex + 1) % media.length].default}
          prevSrc={media[(photoIndex + media.length - 1) % media.length].default}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + media.length - 1) % media.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % media.length)}
        />
      )}
    </div>
  );
};

export default ImageGallery;
