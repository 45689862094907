import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import SongList from './songlist.js';
import Songrequest from './Suggestionbutton2.jsx';

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Mmodal extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="btn11-hover color-1" onClick={this.onOpenModal}><b>Song List</b></button>
        <Modal open={open} onClose={this.onCloseModal} center classNames={{ modal: 'custom-modal' }}>
        <center><h2><b>Song List:</b></h2>
          <div className="modal-header">
          </div>
          <small>
            <i style={{ color: 'black' }}>
              Please note that this is just a selection of the songs we perform. We are constantly adding new ones! 
            <Songrequest />
            </i>
          </small></center>
          <hr className="yellow" />
          <div className="modal-body">
            <SongList />
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Mmodal;
