import React,{useState} from 'react'
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import '../component/common/swiper.css'
import ImageGallery  from "../dark/Gallery";
import RandomImageComponent from './randomimg';
import Songlist from './modal'
import Mp3Player from './preview'
import Credit from './credit'
import '../dark/mp3.css';



const imageArray2 = [
  'https://i.imgur.com/jDESwGR.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
]
const MediaPage = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false)
  return (
    <div className="active-dark">
      <Helmet pageTitle="River Cats" />

      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <section>
</section>
      {/* End Slider Area   */}
      
      {/* Start Portfolio Area */}
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
        <center><h1>Media</h1></center>
          <div className="portfolio-sacousel-inner">
                 
                 <section class="light">
	<div class="container py-2">
		
    

      <article class="postcard light yellow">
			<div class="postcard__text t-dark">
				<h1 class="postcard__title yellow">Media</h1>
				<div class="postcard__subtitle small">
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">

<p>No matter if you're organizing a party, corporate function, or special celebration, booking River Cats ensures a musical experience that will make a memorable impact. Let’s craft unforgettable moments together—reserve River Cats for your next event and let the music do the talking. Your audience deserves excellence, and we’re here to provide it.</p>
<img class="gallerymain" src="https://i.imgur.com/sOSkRXc.jpeg" alt="Larg Crowd Watching River Cats"></img>
    <br/>
    
</div>
			</div>
		</article>
    
  <article className="gallery123">
  
  <ImageGallery/>
  <center><Credit/>

  <br/>
  <br/>
  <h3
  className="postcard__title"
  style={{ color: 'black', fontSize: '18px' }}
>
  Listen to previews of some songs we play below
</h3>


  <Mp3Player/></center>
  <br/>
      </article>
      <br/>
		<article class="postcard light yellow">
			<div class="postcard__img_link">
      <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
			</div>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title yellow">Follow us everywhere!</h1>
				<div class="postcard__subtitle small">
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">

<p>Join us on these platforms to immerse yourself in a world of musical wonders. Experience the magic firsthand by catching our electrifying live performances, where the stage comes alive with the energy of River Cats. Dive into the heart of our creative process and discover exclusive behind-the-scenes moments that unveil the passion and dedication fueling our music.</p>
<p>Stay tuned for the latest updates and exclusive content! Follow us on:</p>
</div>
				<ul class="postcard__tagbox">
        <a href="https://www.youtube.com/@River-Cats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Youtube</li></a>
        <a href="https://www.facebook.com/RiverCatsYGK/" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Facebook</li></a>
        <a href="https://www.twitch.tv/river_cats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Twitch</li></a>
        <a href="https://www.reverbnation.com/rivercats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Reverbnation</li></a>
        <a href="https://www.instagram.com/rivercatsygk/" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Instagram</li></a>
				</ul><br/><Songlist/>
			</div>
		</article>

            
	</div>
</section>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

     

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default MediaPage;
