import React, { useState } from 'react';
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from './contactbutton2';
import '../component/common/swiper.css';
import RandomImageComponent from './randomimg';
import Songlist from './modal';

const imageArray2 = [
  'https://i.imgur.com/jDESwGR.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
];

const Support = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="active-dark">
      <Helmet pageTitle="River Cats" />
      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      <section></section>
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <br />
            <br />
            <section className="light">
              <div className="container py-2">
                <div>
                 
     <a href="https://ko-fi.com/rivercats" rel="noreferrer" target="_blank">  <center><img className="donateslider" src="https://i.imgur.com/70st5Hm.jpeg" alt="Support Us"></img></center></a>
                </div><br/>

    <article className="postcard light yellow">
                  <div className="postcard__img_link">
                    <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
                  </div>
                  <div className="postcard__text t-dark">
                    <h1 className="postcard__title yellow">Follow us everywhere!</h1>
                    <div className="postcard__subtitle small"></div>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt"><p>
  We are River Cats, a passionate group of musicians dedicated to bringing the energy and spirit of live music to our fans. While we don’t need support to keep going, any help you offer would mean the world to us and take our performances to the next level. Here’s how your generosity can make a difference:
</p>

<p>
  <strong>Facilitating Our Shows:</strong> Your support will help us cover essential costs like transportation and promotional materials, ensuring we can continue to perform and connect with our audience.
</p>

<p>
  <strong>Upgrading Equipment:</strong> To deliver the best sound possible, we need to invest in high-quality equipment. This includes wireless units, cables, microphones, and other gear that will enhance our performances and provide a professional experience for our fans.
</p>

<p>
  <strong>Maintenance and Repairs:</strong> Keeping our equipment in top shape is crucial for every show. Your contributions will help us maintain and repair our gear, so we’re always ready to rock out.
</p>

<p>
  Every tip, big or small, brings us one step closer to achieving our goals. Thank you for believing in us and supporting River Cats. Let’s keep the music alive together!
</p>

<p>
  You can also support us by getting merch at <a href="/shop" target="_blank" rel="noopener noreferrer"><b>our online shop</b></a>.
</p>

<p>
  Rock on,<br />
  River Cats
</p>
                    </div>
                                    <br /> <a 
                  href="https://ko-fi.com/rivercats" 
                  rel="noreferrer" 
                  target="_blank" 
                  style={{ 
                    display: 'block', 
                    margin: '0 auto', 
                    fontSize: '24px', 
                    textAlign: 'center', 
                    color: '#000', 
                    textDecoration: 'none', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    width: 'fit-content' 
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = '#f5f5f5';
                    e.currentTarget.style.backgroundColor = '#E1232A';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#000';
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }}
                >
                  <b>Click Here to Support</b>
                </a>
                  </div>
  <br />
  <br />
                </article>
                <br />
                <br />
                <ContactButton />
                <br />
                <article className="postcard light yellow">
                  <div className="postcard__img_link">
                    <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
                  </div>
                  <div className="postcard__text t-dark">
                    <h1 className="postcard__title yellow">Follow us everywhere!</h1>
                    <div className="postcard__subtitle small"></div>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt">
                      <p>Join us on these platforms to immerse yourself in a world of musical wonders. Experience the magic firsthand by catching our electrifying live performances, where the stage comes alive with the energy of River Cats. Dive into the heart of our creative process and discover exclusive behind-the-scenes moments that unveil the passion and dedication fueling our music.</p>
                      <p>Stay tuned for the latest updates and exclusive content! Follow us on:</p>
                    </div>
                    <ul className="postcard__tagbox">
                      <a href="https://www.youtube.com/@River-Cats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Youtube</li></a>
                      <a href="https://www.facebook.com/RiverCatsYGK/" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Facebook</li></a>
                      <a href="https://www.twitch.tv/river_cats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Twitch</li></a>
                      <a href="https://www.reverbnation.com/rivercats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Reverbnation</li></a>
                      <a href="https://www.instagram.com/rivercatsygk/" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Instagram</li></a>
                    </ul>
                    <br /><Songlist />
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
      <FooterTwo />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default Support;
