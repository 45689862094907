import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import styled from '@emotion/styled';

const PopoutContainer = styled(Box)`
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  transition: right 0.3s ease-in-out;
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none; /* Hide the entire component on mobile devices */
  }
`;

const TabControlButton = styled(IconButton)`
  background-color: #3b5998;
  color: white;
  border-radius: 0;
  &:hover {
    background-color: #3b5998;
  }
`;

const FeedContainer = styled(Box)`
  width: 400px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 12px 0 0 12px;
`;

const FacebookFeedContent = styled(Box)`
  width: 100%;
`;

const SidePopoutTab = () => {
  const [isFeedVisible, setIsFeedVisible] = useState(false);

  const handleTabClick = () => {
    setIsFeedVisible(!isFeedVisible);
  };

  return (
    <PopoutContainer style={{ right: isFeedVisible ? '0' : '-400px' }}>
      <TabControlButton onClick={handleTabClick}>
        <FacebookIcon />
      </TabControlButton>
      <FeedContainer>
        <FacebookFeedContent>
          <div className="fb-page" 
               data-href="https://www.facebook.com/rivercatsygk" 
               data-tabs="timeline" 
               data-width="400" 
               data-height="" 
               data-small-header="true" 
               data-adapt-container-width="true" 
               data-hide-cover="false" 
               data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/rivercatsygk" 
                        className="fb-xfbml-parse-ignore">
              <a href="https://www.facebook.com/rivercatsygk">Follow River Cats on Facebook Here</a>
            </blockquote>
          </div>
        </FacebookFeedContent>
      </FeedContainer>
    </PopoutContainer>
  );
};

export default SidePopoutTab;
