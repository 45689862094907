import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Bios/biostyle.css"; // Include custom CSS for animations

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Davebio extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="newbtnsonglist1" onClick={this.onOpenModal}><b>Biography</b></button>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          animationDuration={500} // Animation duration for modal open
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
        >
          <div className="modal-header">
            <img
              src="https://i.imgur.com/cVbrJXm.png"
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto"
              }}
            />
          </div>
          <hr className="yellow" />
          <div className="modal-body">
            <img
              src="https://i.imgur.com/jDESwGR.png"
              alt="Dave"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                border: "5px solid #E1232A",
                float: "left",
                marginRight: "15px"
              }}
            />
            <p style={{ color: '#000', fontSize: '16px', textAlign: 'left', textIndent: '20px' }}>
              Dave has been playing in bands around Ontario and Quebec for over 30 years. Dave recently relocated from Toronto and now calls the Kingston area home. In addition to playing guitar, Dave plays the drums, dobro, slide guitar, and mandolin. Dave enjoys collecting guitars and amplifiers. His diverse knowledge of "that special tone" allows him to pick and choose the best sounding instrument for the task at hand. Dave's background as a hard rocking 70’s and 80’s style guitarist lends an edge to the River Cats' sound.
            </p>
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Davebio;