import React,{useState} from 'react'
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from '../dark/contactbutton2'
import '../component/common/swiper.css'
import kiri from '../assets/images/1.png';
import G from '../assets/images/2.png';
import Greg from '../assets/images/3.png';
import Dave1 from '../assets/images/5.png';
import Dave2 from '../assets/images/4.png';
import RandomImageComponent from './randomimg';
import Songlist from './modal'
import Davebio from '../dark/Bios/Dave'
import Gbio from '../dark/Bios/G'
import Gregbio from '../dark/Bios/Greg'
import Kiribio from '../dark/Bios/Kiri'
import Reconbio from '../dark/Bios/Recon'
import Slider from '../dark/accordianpanels'
import Team from '../dark/Bios/Biomobile'


const imageArray2 = [
  'https://i.imgur.com/jDESwGR.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
];

const PortfolioLanding = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="active-dark">
      <Helmet pageTitle="River Cats" />

      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <section>
</section>
      {/* End Slider Area   */}
      
      {/* Start Portfolio Area */}
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
                 <section class="light">
	<div class="container py-2">
  <Slider/>
		
  <article class="postcard light red">
			<div class="postcard__text t-dark">
				<h1 class="postcard__title red">About Us</h1>
				<div class="postcard__subtitle small">
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt" id="aboutus"> <p>River Cats, a dynamic fusion of funk, rock, and country. This tight-knit ensemble is more than just a band; they're a musical experience that leaves audiences grooving and coming back for more.<p/>
  
<p>Whether you're hosting a party, corporate event, or special occasion, booking River Cats brings a musical experience that will leave a lasting impression. Join us in creating unforgettable moments—book River Cats for your next event and let the music speak volumes. Your audience deserves nothing less than the extraordinary, and we're here to deliver. Contact us today to book us for your event!</p>

   </p>
   
   <ContactButton/>
        </div>
			</div>
		</article>
    <br/>
    <article class="postcard light red">
    <Team/>
    <span class="cards-cover">
          <article class="grid-x5--cards tc-dark-orange">
          <div class="cards ">
              <img src={Dave1} alt="Dave Guitarist"></img>
              <p>Dave</p>
              <Reconbio/>
              <p class="bg-dark-orange">Guitar</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-light-purple">
          <div class="cards ">
              <img src={G} alt="G Bass/Synth"></img>
              <p>G</p>
              <Gbio/>
              <br/>
             <p class="bg-light-purple">Bass</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-orange">
          <div class="cards ">
              <img src={kiri} alt="Kiri Vocals"></img>
              <p>Kiri</p>
              <Kiribio/>
              <br/>
            <p class="bg-orange">Vocals</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-light-purple">
          <div class="cards ">
              <img src={Greg} alt="Greg Drums/Percussion"></img>
              <p>Greg</p>
              <Gregbio/>
              <br/>
             <p class="bg-light-purple">Drums</p>
          </div>
      </article>
          <article class="grid-x5--cards tc-dark-orange">
          <div class="cards tc-dark-orange">
              <img src={Dave2} alt="Dave Guitarist"></img>
              <p>Dave</p>
              <Davebio/>
              <br/>
            <p class="bg-dark-orange">Guitar</p>
          </div>
      </article>
      </span>
		</article>
		<article class="postcard light yellow">
			<div class="postcard__img_link">
      <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
			</div>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title yellow">Follow us everywhere!</h1>
				<div class="postcard__subtitle small">
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">

<p>Join us on these platforms to immerse yourself in a world of musical wonders. Experience the magic firsthand by catching our electrifying live performances, where the stage comes alive with the energy of River Cats. Dive into the heart of our creative process and discover exclusive behind-the-scenes moments that unveil the passion and dedication fueling our music.</p>
<p>Stay tuned for the latest updates and exclusive content! Follow us on:</p>
</div>
				<ul class="postcard__tagbox">
        <a href="https://www.youtube.com/@River-Cats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Youtube</li></a>
        <a href="https://www.facebook.com/RiverCatsYGK/" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Facebook</li></a>
        <a href="https://www.twitch.tv/river_cats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Twitch</li></a>
        <a href="https://www.reverbnation.com/rivercats" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Reverbnation</li></a>
        <a href="https://www.instagram.com/rivercatsygk/" target='_blank' rel="noreferrer" ><li class="tag__item"><i class="fas fa-tag mr-2"></i>Instagram</li></a>
				</ul><br/><Songlist/>
			</div>
		</article>

	</div>
</section>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

     

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;