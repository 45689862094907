import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Bios/biostyle.css"; // Include custom CSS for animations

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Reconbio extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="newbtnsonglist1" onClick={this.onOpenModal}><b>Biography</b></button><br/><br/>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          animationDuration={500}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
        >
          <div className="modal-header">
            <img
              src="https://i.imgur.com/k5OlwzH.png" 
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto"
              }}
            />
          </div>
          <hr className="yellow" />
          <div className="modal-body">
            <img
              src="https://i.imgur.com/W687FqV.png" 
              alt="Recon"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                border: "5px solid #E1232A",
                float: "left",
                marginRight: "15px"
              }}
            />
            <p style={{ color: '#000', fontSize: '16px', textAlign: 'left', textIndent: '20px' }}>
            Dave, known online as Recon or Recontastic, is a Canadian musician whose love for music ignited in his childhood, fueled by his parents' guitar and drum sessions to Guns N' Roses and other 80s metal in their basement. Immersed in a rich blend of 80s metal, 90s punk, and a spectrum of other genres, Recon honed his craft in the online music scene. Over the past 11 years on Twitch.tv, he has captivated thousands with his spontaneous jams and infectious enthusiasm for music. Beyond his musical prowess, Dave is also a tech aficionado with expertise from web design to 3D animation.            </p>
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Reconbio;
