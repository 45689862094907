import React, { useState } from 'react';

const SidePopoutTab = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={`side-tab-container ${open ? 'open' : 'closed'}`}>
      <div className="tab-button" onClick={handleToggle}>
        <span className="icon">F</span> {/* Placeholder for Facebook Icon */}
      </div>
      <div className="facebook-feed">
        <div className="close-button" onClick={handleToggle}>
          <span className="icon">X</span> {/* Placeholder for Close Icon */}
        </div>
        <div className="fb-page" 
             data-href="https://www.facebook.com/rivercatsygk" 
             data-tabs="timeline" 
             data-width="400" 
             data-height="" 
             data-small-header="true" 
             data-adapt-container-width="true" 
             data-hide-cover="false" 
             data-show-facepile="true">
          <blockquote cite="https://www.facebook.com/rivercatsygk" 
                      className="fb-xfbml-parse-ignore">
            <a href="https://www.facebook.com/rivercatsygk">Follow River Cats on Facebook Here</a>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default SidePopoutTab;
