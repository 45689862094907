import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Bios/biostyle.css"; // Include custom CSS for animations

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Gbio extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="newbtnsonglist2" onClick={this.onOpenModal}><b>Biography</b></button>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          animationDuration={500} // Animation duration for modal open
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
        >
          <div className="modal-header">
            <img
              src="https://i.imgur.com/DAtFcYz.png" // Example full-width image (replace as needed)
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto"
              }}
            />
          </div>
          <hr className="yellow" />
          <div className="modal-body">
            <img
              src="https://i.imgur.com/Rn6Mx5F.png" 
              alt="G"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                border: "5px solid #E1232A",
                float: "left",
                marginRight: "15px"
              }}
            />
            <p style={{ color: '#000', fontSize: '16px', textAlign: 'left', textIndent: '20px' }}>
              "G" has been a long-time lover of music as far back as he can remember. Growing up in Montreal, he polished his skills playing with a number of bands in places like "The Moustache", The Maples Inn, and Club Soda. "G" is heavily influenced by great bass players like Steve Harris, Geezer Butler, Roger Glover, and Geddy Lee. Branching out to more than just rock music, "G" has put down bass tracks for fusion, funk, blues, and pop tunes. He also has experience recording music and running live sound. In his spare time, "G" is the producer and host of an internationally syndicated radio show called Progressive Emporium.
            </p>
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Gbio;
