import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../Bios/biostyle.css"; // Include custom CSS for animations

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

class Gregbio extends React.Component {
  state = {
    open: false
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div style={styles}>
        <button className="newbtnsonglist2" onClick={this.onOpenModal}><b>Biography</b></button>
        <Modal
          open={open}
          onClose={this.onCloseModal}
          animationDuration={500} // Animation duration for modal open
          classNames={{
            overlay: "customOverlay",
            modal: "customModal"
          }}
        >
          <div className="modal-header">
            <img
              src="https://i.imgur.com/z2ileNM.png" 
              alt="Banner"
              style={{
                width: "100%",
                height: "auto",
                margin: "0 auto"
              }}
            />
          </div>
          <hr className="yellow" />
          <div className="modal-body">
            <img
              src="https://i.imgur.com/MXYJg2M.png" 
              alt="Greg"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                border: "5px solid #E1232A",
                float: "left",
                marginRight: "15px"
              }}
            />
            <p style={{ color: '#000', fontSize: '16px', textAlign: 'left', textIndent: '20px' }}>
              Greg brings years of rock-steady experience behind the kit. He has played a variety of genres, from rock and reggae to blues and southern-fried rock. Greg was a staple behind the kit in a touring band called Rockin Horse. After a few years on the road, he took a break that ended up lasting over 25 years, but he soon found his chops again with Stonecold Canada. Greg was only too happy to start playing again and is a founding member of the River Cats, where he is part of a solid rhythm section.
            </p>
          </div>
          <hr className="yellow" />
        </Modal>
      </div>
    );
  }
}

export default Gregbio;
