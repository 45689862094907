import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, Box, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

const Mp3Player = () => {
  // Hardcoded playlist
  const playlist = [
    { title: 'Venus', src: '/songs/Venus.mp3' },
    { title: 'Here For The Party', src: '/songs/Party.mp3' },
    { title: 'Black Velvet', src: '/songs/Velvet.mp3' },
    { title: 'Levitating', src: '/songs/Levitating.mp3' },
    { title: 'Tennessee Whiskey', src: '/songs/Whiskey.mp3' }
  ];

  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const audioRef = useRef(null);

  // Effect to handle volume change
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Effect to handle playback when a new song is selected
  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [currentSongIndex, isPlaying]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleNext = () => {
    const nextIndex = (currentSongIndex + 1) % playlist.length;
    setCurrentSongIndex(nextIndex);
    setIsPlaying(true); // Ensure the new song plays automatically
  };

  const handlePrevious = () => {
    const prevIndex = (currentSongIndex - 1 + playlist.length) % playlist.length;
    setCurrentSongIndex(prevIndex);
    setIsPlaying(true); // Ensure the previous song plays automatically
  };

  const handleEnded = () => {
    handleNext(); // Automatically go to the next song when the current song ends
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  return (
    <Box className="mp3-player">
      <Typography className="title">
        {playlist[currentSongIndex].title}
      </Typography>
      <audio 
        ref={audioRef} 
        src={playlist[currentSongIndex].src} 
        onEnded={handleEnded}
      />
      <Box className="player-controls">
        <Button onClick={handlePrevious} disabled={playlist.length <= 1}>
          <SkipPreviousIcon style={{ color: '#ff0000' }} /> {/* Red */}
        </Button>
        <Button onClick={handlePlayPause}>
          {isPlaying ? <PauseIcon style={{ color: '#ff0000' }} /> : <PlayArrowIcon style={{ color: '#ff0000' }} />} {/* White */}
        </Button>
        <Button onClick={handleNext} disabled={playlist.length <= 1}>
          <SkipNextIcon style={{ color: '#ff0000' }} /> {/* Red */}
        </Button>
        <Box className="volume-control">
          <Slider
            value={volume}
            min={0}
            max={1}
            step={0.01}
            onChange={handleVolumeChange}
            aria-labelledby="volume-slider"
            sx={{ color: '#ffffff', marginLeft: 2, width: 100 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Mp3Player;
